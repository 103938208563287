import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b2a6a660"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CxGgh" }
const _hoisted_2 = { style: {"padding-bottom":"20px"} }
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_4 = { style: {"font-size":"20px","font-weight":"bold","color":"dodgerblue"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 1
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "品牌",
                      prop: "brandId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('brandId',el),
                          modelValue: _ctx.form.brandId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.brandId) = $event)),
                          clearable: "",
                          placeholder: "请选择品牌",
                          style: {"width":"100%"},
                          disabled: _ctx.disabled || _ctx.otherParams.disableEdit,
                          onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'brandId')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'brandId'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "车型",
                      prop: "cxId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('cxId',el),
                          modelValue: _ctx.form.cxId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.cxId) = $event)),
                          clearable: "",
                          placeholder: "请选择车型",
                          style: {"width":"100%"},
                          disabled: _ctx.disabled || !_ctx.form.brandId || _ctx.otherParams.disableEdit,
                          onChange: _cache[3] || (_cache[3] = (val)=>{_ctx.selectOnChange(val,'cxId')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'cxId'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "车型公告号",
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[4] || (_cache[4] = e => _ctx.form.name = _ctx.valid(e)),
                          modelValue: _ctx.form.name,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.name) = $event)),
                          placeholder: "请输入车型公告号",
                          maxlength: "100",
                          disabled: _ctx.disabled || _ctx.otherParams.disableEdit,
                          clearable: ""
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherParams.detailInfos, (item, index) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_el_card, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, "配置" + _toDisplayString(index<9?'0'+(index+1):(index+1)), 1),
              _createElementVNode("div", null, [
                (item.id && item.flag==0)
                  ? (_openBlock(), _createBlock(_component_el_popconfirm, {
                      key: 0,
                      title: "确定下架该配置？",
                      onConfirm: ($event: any) => (_ctx.changeStatusConfig(index,1))
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "warning",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("下架配置")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"]))
                  : _createCommentVNode("", true),
                (item.flag)
                  ? (_openBlock(), _createBlock(_component_el_popconfirm, {
                      key: 1,
                      title: "确定上架该配置？",
                      onConfirm: ($event: any) => (_ctx.changeStatusConfig(index,0))
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "success",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("上架配置")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_popconfirm, {
                  title: "确定删除该配置？",
                  onConfirm: ($event: any) => (_ctx.deleteConfig(index))
                }, {
                  reference: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("删除配置")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"])
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              "label-width": "100px",
              style: {"padding-top":"20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "配置名称" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: e => item.name = _ctx.valid(e),
                              modelValue: item.name,
                              "onUpdate:modelValue": ($event: any) => ((item.name) = $event),
                              placeholder: "请输入配置名称",
                              maxlength: "20",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["onInput", "modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "属性" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: item.property,
                              "onUpdate:modelValue": ($event: any) => ((item.property) = $event),
                              placeholder: "请选择属性",
                              style: {"width":"100%"},
                              disabled: _ctx.disabled
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'property'}), (_item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: _item.value,
                                    label: _item.label,
                                    value: _item.value,
                                    disabled: _item.disabled
                                  }, null, 8, ["label", "value", "disabled"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "上市国家" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: item.countryId,
                              "onUpdate:modelValue": ($event: any) => ((item.countryId) = $event),
                              clearable: "",
                              placeholder: "请选择上市国家",
                              style: {"width":"100%"},
                              multiple: "",
                              disabled: _ctx.disabled,
                              "collapse-tags": "",
                              "collapse-tags-tooltip": "",
                              "max-collapse-tags": 10
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'countryId'}), (_item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: _item.value,
                                    label: _item.label,
                                    value: _item.value,
                                    disabled: _item.disabled
                                  }, null, 8, ["label", "value", "disabled"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "成品码" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: item.cpmId,
                              "onUpdate:modelValue": ($event: any) => ((item.cpmId) = $event),
                              placeholder: "请选择成品码",
                              style: {"width":"100%"},
                              multiple: "",
                              disabled: _ctx.disabled,
                              "collapse-tags": "",
                              "collapse-tags-tooltip": "",
                              "max-collapse-tags": 10
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'cpmId'}), (_item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: _item.value,
                                    label: _item.label,
                                    value: _item.value,
                                    disabled: _item.disabled
                                  }, null, 8, ["label", "value", "disabled"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_form_item, { label: "配置英文描述" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      type: "textarea",
                      onInput: e => item.enDesc = _ctx.valid(e),
                      modelValue: item.enDesc,
                      "onUpdate:modelValue": ($event: any) => ((item.enDesc) = $event),
                      placeholder: "请输入配置英文描述",
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["onInput", "modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_form_item, { label: "配置中文描述" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      type: "textarea",
                      onInput: e => item.zhDesc = _ctx.valid(e),
                      modelValue: item.zhDesc,
                      "onUpdate:modelValue": ($event: any) => ((item.zhDesc) = $event),
                      placeholder: "请输入配置中文描述",
                      disabled: _ctx.disabled,
                      clearable: ""
                    }, null, 8, ["onInput", "modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 256)),
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.addConfig
      }, {
        default: _withCtx(() => [
          _createTextVNode("增加配置")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}